import React from 'react';
import {dsnCN} from "../../../hooks/helper";

function MenuContent({className}) {
    const socialData = [
        {link: "https://www.facebook.com/profile.php?id=100054348049947", name: "Facebook."},
        {link: "https://www.instagram.com/afoi_tsopanoglou/", name: "Instagram."},
        {link: "mailto:afoi_tsopanoglou@yahoo.gr", name: "Email."},
    ];
    return (
        <div className={dsnCN('container-content  d-flex flex-column justify-content-center', className)}>
            <div className="nav__info">
                <div className="nav-content">
                    <h5 className="sm-title-block mb-10">Τα γραφεία μας</h5>
                    <p>
                        Σουλίου 179 <br/> Πετρούπολη, Αθήνα
                    </p>

                </div>
                <div className="nav-content mt-30">
                    <h5 className="sm-title-block mb-10">Επικοινωνία</h5>
                    <p className="links over-hidden mb-1">
                        <a className="link-hover" href="tel:210 5058446" data-hover-text="210 5058446">
                        210 5058446
                        </a>
                    </p>
                    <p className="links over-hidden">
                        <a className="link-hover" href="mailto:afoi_tsopanoglou@yahoo.gr"
                           data-hover-text="afoi_tsopanoglou@yahoo.gr">afoi_tsopanoglou@yahoo.gr</a>
                    </p>
                </div>
            </div>
            <div className="nav-social nav-content mt-30">
                <div className="nav-social-inner p-relative">
                    <h5 className="sm-title-block mb-10">Ακολουθήστε μας</h5>
                    <ul>
                        {socialData.map((item, index) =>
                            <li key={index}>
                                <a href={item.link} target="_blank" rel="nofollow noopener noreferrer">{item.name}</a>
                            </li>)}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default MenuContent;